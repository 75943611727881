<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay"
                 opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12">
          <v-card :disabled="overlay" :loading="overlay" class="rounded-lg shadow">
            <v-card-title>

              <div>
                <v-text-field v-model="keyword"
                              append-icon="mdi-magnify"
                              class="w-300"
                              clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>

              <v-spacer/>

              <v-btn class="text-none" disabled text>
                {{ total }} Élément(s)
              </v-btn>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="drawer = true"
                         v-on="on">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>

              <v-btn class="ml-1" color="blue" dark depressed fab small
                     @click="$router.push('/statement-campaigns/create')">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-card-text v-if="!loading && satementCampaigns.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>
            <div v-if="!loading && satementCampaigns.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Statut</th>
                    <th>Photos</th>
                    <th>Nom</th>
                    <th>Planification</th>
                    <th class="text-center">Superviseurs</th>
                    <th class="text-center">KPI'S</th>
                    <th class="text-center">Jours <br> restants</th>
                    <th class="text-center">Total <br> points gagnés</th>
                    <th>Créer le</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,i) in satementCampaigns" :key="i">

                    <td>
                      <v-switch v-model="item.status"
                                class="pa-0 ma-0"
                                color="blue"
                                hide-details
                                readonly
                                @click="changeStatus(item.id)"/>
                    </td>

                    <td>
                      <div class="pa-2">

                        <div v-if="item.photos.length" class="d-flex">
                          <v-img v-for="(photo,i) in item.photos"
                                 :key="i"
                                 :src="FILE_URL + photo"
                                 class="rounded zoom-pointer mx-1 my-2"
                                 height="30"
                                 width="30" @click="[image = [{src: FILE_URL + photo}],visible = true]"/>
                        </div>

                        <v-img v-else :src="require('@/assets/no-image.png')"
                               class="rounded img-border"
                               height="45" width="45"/>
                      </div>
                    </td>

                    <td>{{ item.name }}</td>

                    <td>
                      {{ item.start_date }} ~ {{ item.end_date }}
                    </td>

                    <td class="text-center">
                      <v-chip color="success" dark small @click="$refs.supervisorsDialog.open(item)">
                        <v-icon left small>mdi-account-multiple-check-outline</v-icon>
                        {{ item.supervisors.length }}
                      </v-chip>
                    </td>

                    <td class="text-center">
                      <v-chip :disabled="!(item.k_p_is.length)" color="blue" dark small
                              @click="$refs.KPIsDialog.open(item.k_p_is)">
                        <v-icon left small>mdi-clipboard-text-outline</v-icon>
                        {{ item.k_p_is.length }}
                      </v-chip>
                    </td>

                    <td class="text-center">
                      <v-chip :color="item.remaining_days.color" dark small> {{ item.remaining_days.value }}</v-chip>
                    </td>

                    <td class="text-center">
                      <strong class="text-primary">
                        {{ item.total_winning_points | toCurrency }}
                      </strong>
                    </td>

                    <td>
                      {{ item.created_at }}
                    </td>

                    <td>
                      <div class="d-flex">
                        <v-menu bottom right transition="slide-x-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item :to="'/statement-campaigns/'+item.id+'/edit'">
                              <v-list-item-title>
                                <v-icon dense left>mdi-pencil-outline</v-icon>
                                Modifier
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItem(item.id)">
                              <v-list-item-title>
                                <v-icon dense left>mdi-trash-can-outline</v-icon>
                                Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider v-if="total > 10"/>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total > 10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>


            </div>
          </v-card>
        </v-col>
      </v-row>

      <!--Dialogs && Drawer-->
      <KPIsDialog ref="KPIsDialog"/>
      <SupervisorsDialog ref="supervisorsDialog"/>

      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"></vue-easy-lightbox>


      <v-navigation-drawer
          v-model="drawer"
          :permanent="drawer" app
          clipped
          floating
          right
          temporary
          width="400">
        <v-list-item>
          <v-list-item-avatar color="blue">
            <v-icon dark>mdi-tune</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Filtrages</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <FilterDrawer ref="FilterDrawer" @filter="[getStatementCampaigns($event),drawer=false]"/>
      </v-navigation-drawer>

      <!--Dialogs && Drawer-->

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import FilterDrawer from "./FilterDrawer";
import NoResult from "@/components/NoResult.vue";
import KPIsDialog from "@/views/statementCampaigns/components/KPIsDialog.vue";
import SupervisorsDialog from "@/views/statementCampaigns/components/SupervisorsDialog.vue";


export default {
  components: {KPIsDialog, NoResult, SupervisorsDialog, FilterDrawer},
  data() {
    return {
      overlay: false,
      loading: false,
      drawer: false,

      id: '',
      satementCampaigns: [],
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
      keyword: '',
      filter: {},

      //Show image
      image: '',
      visible: false,
      index: 0,
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    getStatementCampaigns(filter) {
      this.loading = true
      this.$Progress.start()
      this.filter = filter ? filter : this.filter
      HTTP.get('/statement-campaigns?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          filter: this.filter,
          per_page: this.per_page,
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()

        this.satementCampaigns = res.data.data.data;
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;


      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    onPageChange() {
      this.getStatementCampaigns();
    },
    exportExcel(asmCampaignId) {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/statement-campaigns/' + asmCampaignId + '/responses?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    refresh() {
      this.filter = {}
      this.getStatementCampaigns()
    },
    handleDelete() {
      this.$is_confirm = false
      this.overlay = true
      this.$Progress.start()
      HTTP.delete('/statement-campaigns/delete/' + this.id).then(() => {
        let i = this.satementCampaigns.findIndex(item => item.id === this.id)
        this.$delete(this.satementCampaigns, i)
        this.$successMessage = 'Cette campagne a été supprimée avec succès'
        this.overlay = false
        this.$Progress.finish()
      }).catch(err => {
        this.overlay = false
        this.$Progress.fail()
        console.log(err)
      })
    },
    changeStatus(id) {
      this.overlay = true
      HTTP.post('/statement-campaigns/change-status', {
        id: id
      }).then(() => {
        this.overlay = false
        this.getStatementCampaigns()
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    }
  },
  created() {
    this.getStatementCampaigns()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.handleDelete()
      }
    },
    keyword() {
      this.getStatementCampaigns();
    },
    per_page() {
      this.pagination.current = 1
      this.getStatementCampaigns();
    },
  },
}
</script>

<style scoped>

</style>